import React from "react";
import { Redirect, useParams } from "react-router-dom";
import Page from "../../components/Page";
import BookForm from "../../components/Book/BookForm";
import { getUniqueValues } from "../../utils/helpers";
import { startCase } from "lodash";
import {
  useBookState,
  useBookDispatch,
  updateBook,
  actionMap
} from "../../contexts/book-context";

function BookEditPage() {
  const { bookId } = useParams();
  const { map } = useBookState();
  const dispatch = useBookDispatch();
  const book = map[bookId];

  if (!book) {
    return <Redirect to="/book" />;
  }

  const booksArr = Object.values(map);

  const yearOptions = getUniqueValues(booksArr, "year").map(y => {
    return {
      key: y,
      value: y,
      text: startCase(y)
    };
  });

  const versionOptions = getUniqueValues(booksArr, "version").map(v => {
    return {
      key: v,
      value: v,
      text: v.toUpperCase()
    };
  });

  const crumbs = [
    {
      name: "Book",
      path: "/book"
    }
  ];

  return (
    <Page title={`Edit Book`} icon="edit" containerWidth="text" crumbs={crumbs}>
      <BookForm
        book={book}
        versionOptions={versionOptions}
        yearOptions={yearOptions}
        bookAction={updates => updateBook(dispatch, bookId, updates)}
        type={actionMap.UPDATE_BOOK}
        text="Update"
      />
    </Page>
  );
}

export default BookEditPage;
