import http from "../utils/http";

export function fetchTasks() {
  return http
    .get("/task")
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

// function createTask(payload) {
//   return http
//     .post("/task", payload)
//     .then(({ data }) => data)
//     .catch(err => {
//       throw new Error(err);
//     });
// }

// function updateTask(taskId, payload) {
//   const config = {
//     params: { task_id: taskId }
//   };
//   return http
//     .patch("/task", payload, config)
//     .then(({ data }) => data)
//     .catch(err => {
//       throw new Error(err);
//     });
// }

// function deleteTask(taskId) {
//   const config = {
//     params: { task_id: taskId }
//   };
//   return http
//     .delete("/task", config)
//     .then(({ data }) => data)
//     .catch(err => {
//       throw new Error(err);
//     });
// }

// export { fetchTasks, createTask, updateTask, deleteTask };
