import React from "react";
import Page from "../../components/Page";
import WeekForm from "../../components/Week/WeekForm";
import { useBookState } from "../../contexts/book-context";
import { Redirect, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  useWeekState,
  useWeekDispatch,
  createWeek
} from "../../contexts/week-context";
import { getBookTitle } from "../../utils/helpers";

function WeekAddPage() {
  const { state } = useLocation();
  const bookId = state ? state.bookId : undefined;

  const { map: bookMap } = useBookState();

  const { map: weekMap } = useWeekState();
  const dispatch = useWeekDispatch();

  if (isEmpty(bookMap)) {
    return <Redirect to={bookId ? `book/${bookId}` : "/book"} />;
  }

  const weeksArr = Object.values(weekMap);
  const doctrineOptions = weeksArr
    .reduce((unique, w) => {
      if (unique.indexOf(w.doctrine_name) === -1) {
        unique.push(w.doctrine_name);
      }
      return unique;
    }, [])
    .map(doctrine => ({ text: doctrine, value: doctrine }));

  const booksArr = Object.values(bookMap);
  const bookOptions = booksArr.map(b => {
    return {
      key: b._id,
      value: b._id,
      text: getBookTitle(b)
    };
  });

  const crumbs = [
    {
      name: "Book",
      path: bookId ? `/book/${bookId}` : "/book"
    },
    {
      name: "Week"
    }
  ];

  return (
    <Page title="Add Week" icon="plus" containerWidth="text" crumbs={crumbs}>
      <WeekForm
        bookId={bookId}
        doctrineOptions={doctrineOptions}
        bookOptions={bookOptions}
        weekAction={week => {
          createWeek(dispatch, week);
        }}
      />
    </Page>
  );
}

export default WeekAddPage;
