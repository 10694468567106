import React from "react";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import { Card } from "semantic-ui-react";

TaskItem.propTypes = {
  task: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    complete: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired
  }).isRequired
};

function TaskItem({ task }) {
  const { name, description } = task;
  return (
    <Card>
      <Card.Content>
        <Card.Header>{startCase(name)}</Card.Header>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
    </Card>
  );
}

export default TaskItem;
