import React from "react";
import Page from "../../components/Page";
import DayForm from "../../components/Day/DayForm";
import { getBookTitle, getUniqueValues } from "../../utils/helpers";
import { isEmpty } from "lodash";
import { useParams, Redirect } from "react-router-dom";
import { useWeekState } from "../../contexts/week-context";
import { useTaskState } from "../../contexts/task-context";
import { useBookState } from "../../contexts/book-context";
import {
  useDayState,
  useDayDispatch,
  actionMap,
  updateDay
} from "../../contexts/day-context";

function WeekEditPage() {
  const { dayId, weekId, bookId } = useParams();
  const dispatch = useDayDispatch();

  const { map: bookMap } = useBookState();
  const { map: weekMap } = useWeekState();
  const { map: dayMap } = useDayState();
  const { map: taskMap } = useTaskState();

  const day = dayMap[dayId];

  const taskArr = Object.values(taskMap);

  if (isEmpty(weekMap)) {
    return <Redirect to="/book" />;
  }

  const weekArr = Object.values(weekMap);
  const weekOptions = weekArr
    .map(w => {
      return {
        key: w._id,
        value: w._id,
        text: `Week ${w.number}`,
        book_id: w.book_id
      };
    })
    .sort((a, b) => (a.text < b.text ? -1 : 1));

  const bookIdsWithWeeks = getUniqueValues(weekArr, "book_id");
  const bookArr = Object.values(bookMap);
  const bookOptions = bookArr
    .filter(b => bookIdsWithWeeks.indexOf(b._id) !== -1)
    .map(b => {
      return {
        key: b._id,
        value: b._id,
        text: getBookTitle(b)
      };
    });

  const crumbs = [
    {
      name: "Book",
      path: `/book/${bookId}`
    },
    {
      name: "Week",
      path: `/book/${bookId}/week/${weekId}`
    },
    {
      name: "Day"
    }
  ];
  return (
    <Page title="Edit Day" icon="edit" containerWidth="text" crumbs={crumbs}>
      <DayForm
        day={day}
        bookId={bookId}
        weekId={weekId}
        bookOptions={bookOptions}
        weekOptions={weekOptions}
        taskOptions={taskArr}
        text="Update"
        type={actionMap.UPDATE_DAY}
        dayAction={updates => {
          updateDay(dispatch, dayId, updates);
        }}
      />
    </Page>
  );
}

export default WeekEditPage;
