import React from "react";

import { Divider } from "semantic-ui-react";
import NotificationContainer from "./Notification/NotificationContainer";

import AppBar from "./AppBar";

function Layout({ children }) {
  return (
    <div>
      <AppBar />

      <Divider section hidden />

      <main>{children}</main>

      <Divider section hidden />

      <NotificationContainer />
    </div>
  );
}

export default Layout;
