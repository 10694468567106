import React from "react";
import Page from "../../components/Page";
import BookList from "../../components/Book/BookList";
import { Button, Icon } from "semantic-ui-react";
import { Link, useRouteMatch } from "react-router-dom";

function BookPage() {
  let { url } = useRouteMatch();

  const action = (
    <Button icon labelPosition="left" as={Link} to={`${url}/add`} primary>
      <Icon name="plus" />
      Add Book
    </Button>
  );

  return (
    <Page title="Books" icon="book" actions={action}>
      <BookList />
    </Page>
  );
}

export default BookPage;
