import React from "react";
import PropTypes from "prop-types";
import { Item, Label, Divider } from "semantic-ui-react";
import WeekItem from "./WeekItem";

WeekDoctrine.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.object).isRequired
};

function WeekDoctrine({ weeks, doctrine }) {
  const weeksCopy = [...weeks];
  weeksCopy.sort((a, b) => a.number - b.number);
  const weekItems = weeksCopy.map(w => <WeekItem key={w._id} week={w} />);
  return (
    <section>
      <Divider hidden />
      <Label as="h2" ribbon color="orange">
        {doctrine}
      </Label>

      <Item.Group divided>{weekItems}</Item.Group>
    </section>
  );
}

export default WeekDoctrine;
