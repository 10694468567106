import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuth } from "../../contexts/authentication-context";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import ResourceProviders from "../../contexts/resource-providers";

function App() {
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      {isAuthenticated ? (
        <ResourceProviders>
          <AuthenticatedApp />
        </ResourceProviders>
      ) : (
        <UnauthenticatedApp />
      )}
    </Router>
  );
}

export default App;
