import axios from "axios";
import { checkAuth, logout } from "../clients/login-client";

const baseURL = `https://${process.env.REACT_APP_API_HOST}/`;

/**
 * Axios instance to be used for requests not requiring auth token
 */
export const httpNoAuth = axios.create({
  baseURL,
  headers: { "Content-Type": "application/x-www-form-urlencoded" }
});

/**
 * Default axios instance configured to check, refresh, and send along auth token
 */
const http = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" }
});

/**
 * Intercepts every request made with the auth instance and checks that token has not expired.
 * If it has, it attempts to refresh the token and send along the new token
 */
http.interceptors.request.use(
  config => {
    return checkAuth()
      .then(auth => {
        const token = auth.idToken ? auth.idToken : null;
        if (token) {
          config.headers.token = `Bearer ${token}`;
        }
        return config;
      })
      .catch(err => {
        logout();
        return Promise.reject(err);
      });
  },
  error => Promise.reject(error)
);

export default http;
