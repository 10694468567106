import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";
import {
  useNotificationDispatch,
  removeNotification
} from "../../contexts/notification-context";

import "./Notification.css";

Notification.propTypes = {
  notification: PropTypes.object.isRequired
};

const TIME_TO_DISMISS = 10000;

function Notification({ notification }) {
  const { id, message, action, dismissable } = notification;
  const dispatch = useNotificationDispatch();

  const handleClose = useCallback(() => {
    removeNotification(dispatch, id);
  }, [dispatch, id]);

  const onDimiss = dismissable ? { onDismiss: handleClose } : {};

  useEffect(() => {
    const timer = !dismissable
      ? setTimeout(handleClose, TIME_TO_DISMISS)
      : null;

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [handleClose, dismissable]);

  return (
    <Message
      color="black"
      compact
      floating
      className="notification"
      {...onDimiss}
    >
      <p className="notification__message">{message}</p>
      {action ? <div className="notification__action">{action}</div> : null}
    </Message>
  );
}

export default Notification;
