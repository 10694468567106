import { httpNoAuth as http } from "../utils/http";
import qs from "querystring";
import moment from "moment";
import { isEmpty } from "lodash";

/**
 * @const {string}
 */
const STORAGE_KEY = "authentication";

/**
 * Handles the response from login endpoints.
 * If status is not 200, display error to user
 * Otherwise, Authentication data is augmented with expiration dates and saved to local storage.
 *
 * @param  {Object} data holds auth, session, and challenge data
 *
 * @return {Object} data
 */
function handleLoginResponse({
  data: { AuthenticationResult, ChallengeName },
  data
}) {
  if (AuthenticationResult || ChallengeName) {
    const auth = normalizeAuthentication(data);
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(auth));
    return auth;
  }

  throw new Error(data.message);
}

/**
 * Normalizes the various responses from login endpoints and returns a consistent auth object
 * idToken, refreshToken, and accessToken are only overwritten if the key is present on the response
 * session and challenge are overwritten
 *
 * @param {Object} res response from login endpoints
 * @return {Object} auth object that will be stored in localStorage and state
 */
function normalizeAuthentication(res) {
  const currentAuth = getAuth();
  const { ChallengeName, AuthenticationResult, Session } = res;
  let newAuth = {};
  let expirations = {};

  if (AuthenticationResult) {
    expirations = generateTokenExpirations(AuthenticationResult);

    if (AuthenticationResult.IdToken) {
      newAuth.idToken = AuthenticationResult.IdToken;
    }

    if (AuthenticationResult.AccessToken) {
      newAuth.accessToken = AuthenticationResult.AccessToken;
    }

    if (AuthenticationResult.RefreshToken) {
      newAuth.refreshToken = AuthenticationResult.RefreshToken;
    }
  }

  newAuth.challenge = ChallengeName ? ChallengeName : "";
  newAuth.session = Session ? Session : "";

  newAuth = {
    ...newAuth,
    ...expirations
  };

  return {
    ...currentAuth,
    ...newAuth
  };
}

/**
 * Generates expiration dates for access and refresh tokens.
 * Only generates dates for present tokens in the auth object.
 * Refresh token expires in 30 days
 * Access token expires based on ExpiresIn property of auth object
 *
 * @param  {Object} auth AuthenticationResult from cognito
 * @return {Object} Expiration dates for either access or refresh tokens
 */
function generateTokenExpirations(auth) {
  let expirations = {};

  if (auth.RefreshToken) {
    expirations.refreshExpires = moment().add(30, "day");
  }

  if (auth.AccessToken) {
    expirations.accessExpires = moment().add(auth.ExpiresIn, "seconds");
  }

  return expirations;
}

/**
 * Determines if refresh token is expired or if a new access token should be requested
 *
 * @param {Object} auth Authentication object
 * @return {Object}
 */
function checkTokenExpiration(auth) {
  const now = moment();
  const { refreshExpires, accessExpires } = auth;

  const isRefreshExpired = moment(refreshExpires).isSameOrBefore(now);

  const shouldGetNewAccess = moment(accessExpires)
    .subtract("5", "minutes")
    .isSameOrBefore(now);

  return { isRefreshExpired, shouldGetNewAccess };
}

/**
 * Checks authentication status:
 * If refresh token is expired, localstorage is cleared and user must re-authenticate.
 * If the access token is expired or expiring soon, refresh token is used to request a new one.
 * If auth is already in localstorage, it is returned
 * Else, the user should authenticate (empty object is returned)
 *
 * @return {Object} Authentication object
 */
function checkAuth() {
  let auth = getAuth();

  if (!isEmpty(auth)) {
    const { isRefreshExpired, shouldGetNewAccess } = checkTokenExpiration(auth);

    if (isRefreshExpired) {
      logout();
      auth = getAuth();
    } else if (shouldGetNewAccess) {
      return http
        .post(
          "/login",
          qs.stringify({
            auth_flow: "REFRESH_TOKEN",
            refresh_token: auth.refreshToken
          })
        )
        .then(response => {
          const auth = handleLoginResponse(response);

          return auth;
        })
        .catch(err => err);
    }
  }
  return Promise.resolve(auth);
}

/**
 * Retrieves authentication object from localStorage
 *
 * @return {Object}
 */
function getAuth() {
  const auth = window.localStorage.getItem(STORAGE_KEY);
  return auth ? JSON.parse(auth) : {};
}

/**
 * Authenticates the user
 *
 * @param {Object} payload username and password
 * @return {Promise<Object>} Authentication object
 */
function login(payload) {
  return http
    .post("/login", qs.stringify(payload))
    .then(response => {
      return handleLoginResponse(response);
    })
    .catch(err => {
      throw err;
    });
}

/**
 * Clears localStorage of authentication information, effectively unauthenticating the user
 */
function logout() {
  window.localStorage.removeItem(STORAGE_KEY);
}

export { login, logout, checkAuth, getAuth };
