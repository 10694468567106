import React from "react";
import PropTypes from "prop-types";

import CKEditor from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import "./Editor.css";

Editor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func,
  onBlue: PropTypes.func
};

function onEventWrapper(e, editor, cb, event) {
  const html = editor.getData();
  cb(e, html, event);
}

const editorConfig = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList"
  ]
};

function Editor({ value, onChange, onBlur }) {
  return (
    <CKEditor
      className="test"
      editor={InlineEditor}
      data={value}
      config={editorConfig}
      onChange={(e, editor) => {
        onEventWrapper(e, editor, onChange, "change");
      }}
      onBlur={(e, editor) => {
        onEventWrapper(e, editor, onBlur, "blur");
      }}
    />
  );
}

export default Editor;
