import React from "react";
import PropTypes from "prop-types";
import { Header, Card, Segment } from "semantic-ui-react";
import { startCase } from "lodash";
import { convertYearToColor } from "../../utils/helpers";
import BookCard from "./BookCard";

BookGroup.propTypes = {
  books: PropTypes.arrayOf(PropTypes.object).isRequired,
  year: PropTypes.string.isRequired
};

function BookGroup({ books, year }) {
  const color = convertYearToColor(year);
  const bookCards = books.map(b => <BookCard book={b} key={b._id} />);

  return (
    <Segment basic as="section">
      <Header size="medium" color={color} dividing>
        {startCase(year)}
      </Header>
      <Card.Group>{bookCards}</Card.Group>
    </Segment>
  );
}

export default BookGroup;
