import React from "react";
import { BookProvider } from "./book-context";
import { WeekProvider } from "./week-context";
import { DayProvider } from "./day-context";
import { TaskProvider } from "./task-context";

function ResourceProviders({ children }) {
  return (
    <BookProvider>
      <WeekProvider>
        <DayProvider>
          <TaskProvider>{children}</TaskProvider>
        </DayProvider>
      </WeekProvider>
    </BookProvider>
  );
}

export default ResourceProviders;
