import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import BookGroup from "./BookGroup";
import { getUniqueValues } from "../../utils/helpers";
import { useBookState } from "../../contexts/book-context";

function BookList() {
  const { map } = useBookState();
  const booksArr = Object.values(map);

  // Grab unique book years
  const bookYears = getUniqueValues(booksArr, "year");

  // Create a BookGroup of BookCards for each year
  const bookGroups = bookYears.reduce((groups, year) => {
    const bookGroupItems = booksArr.filter(b => b.year === year);
    groups.push(<BookGroup key={year} year={year} books={bookGroupItems} />);
    return groups;
  }, []);

  return (
    <>
      {booksArr.length === 0 ? (
        <Segment basic placeholder>
          <Header icon>
            <Icon name="book" />
            No books have been created yet.
          </Header>
        </Segment>
      ) : (
        bookGroups
      )}
    </>
  );
}

export default BookList;
