/**
 * @const {string}
 */
const [PENDING, SUCCESS, ERROR] = ["isPending", "isSuccess", "isError"];

/**
 * @const {string[]}
 */
const statusArr = [PENDING, SUCCESS, ERROR];

/**
 * @const {Object}
 */
const statusMap = {
  PENDING,
  SUCCESS,
  ERROR
};

/**
 * Generates an object representing status of an async request
 * To be used with state reducer actions that fetch data
 * @param {string} [status] - string constant representing a status
 * @return {Object} An object representing status state
 *
 * @example
 *  generateStatusObject(PENDING)
 */
function generateStatusObject(status = "") {
  return statusArr.reduce((o, s) => {
    o[s] = status === s;
    return o;
  }, {});
}

export { statusMap, generateStatusObject };
