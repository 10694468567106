import { useState, useEffect } from "react";

/**
 * Hook that encapsulates general form functionality
 * Controls input values
 * Provides input errors
 * Provides onChange, onBlur, and onSubmit handlers
 * Provides form validity and errors
 *
 * @param {Object} fields        - key is id of input, value is initial input value
 * @param {func}   submitCb      - function to be executed when form is submitted
 * @param {Object} [validators]  - key is id of input, value is Array of validator functions to run on input value
 *
 * @return {Object} form functionality
 *
 * @example
 *    useForm(formInputs, submitFunction, inputValidators = {})
 */
function useForm(fields, submitCb, validators) {
  const initalErrors = initializeErrors();

  const [values, setValues] = useState({ ...fields });
  const [errors, setErrors] = useState(initalErrors);

  // Splitting these into own states so we don't have to deep compare in useEffect
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formError, setFormError] = useState({
    hasError: false,
    title: "",
    message: ""
  });

  // Checks if some fields have been touched, then sets form dirty state
  useEffect(() => {
    function areSomeFieldsTouched() {
      return Object.values(errors).some(value => value.touched);
    }

    const touched = areSomeFieldsTouched();
    setIsFormDirty(touched);
  }, [errors]);

  // If form is dirty, check validity and set state
  useEffect(() => {
    function validateForm() {
      return Object.values(errors).some(value => value.message);
    }

    if (isFormDirty) {
      const invalid = validateForm();
      setIsFormInvalid(invalid);
    }
  }, [isFormDirty, errors]);

  /**
   * Updates field value and validates field value on a change event
   * @param {SyntheticEvent} e      - React onChange event (unused because Semantic UI is dumb)
   * @param {string}         name   - name of field that fired event
   * @param {string|number}  value  - value of input field
   */
  function handleChange(e, { name, value }) {
    const errMsg = validateField(name, value);

    setErrors({
      ...errors,
      [name]: { ...errors[name], message: errMsg }
    });
    setValues({ ...values, [name]: value });
  }

  /**
   * Validates field value on a blur event
   * @param {SyntheticEvent} e      - React onBlur event
   * @param {EventTarget}    target - ref to dispatching object
   */
  function handleBlur({ target }) {
    const { name, value } = target;
    const errMsg = validateField(name, value);

    setErrors({
      ...errors,
      [name]: { touched: true, message: errMsg }
    });
  }

  /**
   * Prevents default browser submit and dirties form
   * If form is in an invalid state, return
   * Otherwise, set the form submitting state and execute the submit callback
   * @param {SyntheticEvent} e - React onSubmit event
   * @return {undefined}
   */
  function handleSubmit(e) {
    e.preventDefault();
    const isFormInvalid = dirtyForm();

    if (isFormInvalid) {
      return;
    }

    setIsSubmitting(true);

    submitCb();
  }

  /**
   * Runs validators on the value of the input
   * In the case of an error, a error string is returned
   * If there is no error, an empty string is returned
   *
   * The order in which validators are given matters
   * Validators error messages from validators at a lower index will be returned first
   *
   * @param  {string}        field - input id
   * @param  {string|number} value - input value
   * @return {string} error string or empty string
   */
  function validateField(field, value) {
    const fieldValidators = validators[field];

    const errStrings = fieldValidators
      ? fieldValidators
          .map(v => {
            return v(field, value);
          })
          .filter(err => err !== "")
      : "";

    return errStrings ? errStrings[0] : errStrings;
  }

  /**
   * Initializes the error state based on the fields passed to the hook
   * @return {Object} errors object
   */
  function initializeErrors() {
    function errorKeys(acc, field) {
      acc[field] = {};
      return acc;
    }
    const errs = Object.keys(fields).reduce(errorKeys, {});

    return errs;
  }

  /**
   * Updates the errors state by validating each field and marking them as touched
   * @return {boolean} true if form is valid, false if not
   */
  function dirtyForm() {
    let numFieldsInvalid = 0;
    let errorsUpdate = {};

    Object.entries(values).forEach(([field, value]) => {
      errorsUpdate[field] = {};
      errorsUpdate[field].message = validateField(field, value);
      errorsUpdate[field].touched = true;
      numFieldsInvalid += errorsUpdate[field].message ? 1 : 0;
    });

    const isFormInvalid = numFieldsInvalid ? true : false;

    setErrors({
      ...errors,
      ...errorsUpdate
    });

    return isFormInvalid;
  }

  return {
    values,
    setValues,
    errors,
    isFormInvalid,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    isSubmitted,
    setIsSubmitted,
    formError,
    setFormError
  };
}

export { useForm };
