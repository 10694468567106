import React from "react";
import Page from "../../components/Page";
import WeekDetail from "../../components/Week/WeekDetail";
import { Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { convertYearToColor } from "../../utils/helpers";
import { getBookTitle } from "../../utils/helpers";
import { Button, Dropdown } from "semantic-ui-react";
import { useBookState } from "../../contexts/book-context";
import { useDayState } from "../../contexts/day-context";
import {
  useWeekState,
  deleteWeek,
  useWeekDispatch,
  actionMap
} from "../../contexts/week-context";
import ConfirmationButton from "../../components/ConfirmationButton";

function WeekDetailPage() {
  const { url } = useRouteMatch();
  const { weekId, bookId } = useParams();
  const { map: bookMap } = useBookState();
  const { map: weekMap, status } = useWeekState();
  const { map: dayMap } = useDayState();

  const dispatch = useWeekDispatch();

  const week = weekMap[weekId];
  const book = bookMap[bookId];
  const bookTitle = getBookTitle(book);
  const color = convertYearToColor(book.year);

  if (!week) {
    return <Redirect to={`/book/${bookId}`} />;
  }

  const daysArr = Object.values(dayMap);
  const weekDays = daysArr.filter(d => d.week_id === weekId);

  const actions = (
    <Button.Group color="blue">
      <Button
        as={Link}
        to={{ pathname: "/day/add", state: { weekId: week._id } }}
      >
        Add Day
      </Button>
      <Dropdown button className="button icon">
        <Dropdown.Menu>
          <Dropdown.Item
            text="Edit Week"
            as={Link}
            to={`${url}/edit`}
            icon="edit"
          />
          <ConfirmationButton
            trigger={<Dropdown.Item text="Delete Week" icon="trash" />}
            handleAction={() => {
              deleteWeek(dispatch, weekId);
            }}
            triggerAction="hover"
            loading={status[actionMap.DELETE_WEEK].isPending}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  );

  const crumbs = [
    {
      name: "Book",
      path: `/book/${bookId}`
    },
    {
      name: "Week"
    }
  ];

  return (
    <Page
      title={`Week ${week.number}`}
      subtitle={bookTitle}
      color={color}
      icon="calendar alternate outline"
      actions={actions}
      crumbs={crumbs}
    >
      <WeekDetail days={weekDays} week={week} />
    </Page>
  );
}

export default WeekDetailPage;
