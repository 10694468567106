import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Form, Message, Segment } from "semantic-ui-react";
import { useForm } from "../../hooks/form-hook";
import { required } from "../../utils/form-validators";
import { useBookState, actionMap } from "../../contexts/book-context";

BookForm.propTypes = {
  book: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }),
  yearOptions: PropTypes.arrayOf(PropTypes.object),
  versionOptions: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.string,
  type: PropTypes.string,
  bookAction: PropTypes.func.isRequired
};

function BookForm({
  book,
  yearOptions,
  versionOptions: versionOptionsFromProps,
  text = "Add",
  type = actionMap.CREATE_BOOK,
  bookAction
}) {
  const [versionOptions, setVersionOptions] = useState([
    ...versionOptionsFromProps
  ]);

  const { status, error } = useBookState();

  const fields = {
    program: book ? book.program : "",
    version: book ? book.version : "",
    year: book ? book.year : ""
  };

  const validators = {
    program: [required],
    version: [required],
    year: [required]
  };

  const submitCallback = () => {
    bookAction(values);
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    formError,
    setFormError,
    isFormInvalid
  } = useForm(fields, submitCallback, validators);

  function handleAddition(e, { value }) {
    setVersionOptions([
      ...versionOptions,
      { text: value.toUpperCase(), key: value, value: value.toLowerCase() }
    ]);
  }

  useEffect(() => {
    if (error) {
      setFormError({
        hasError: true,
        title: `${text} Failed`,
        message: error.message
      });
    }
  }, [setFormError, error, text]);

  return status[type].isSuccess ? (
    <Redirect to="/book" />
  ) : (
    <Segment raised>
      <Form
        onSubmit={handleSubmit}
        loading={status[type].isPending}
        error={formError.hasError}
        noValidate
      >
        <Message error header={formError.title} content={formError.message} />
        <Form.Input
          required
          id="program"
          name="program"
          label="Program"
          error={
            errors.program.touched && errors.program.message
              ? errors.program.message
              : false
          }
          value={values.program}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Form.Group widths={2}>
          <Form.Select
            search
            allowAdditions
            options={versionOptions}
            required
            id="version"
            name="version"
            label="Version"
            error={
              errors.version.touched && errors.version.message
                ? errors.version.message
                : false
            }
            value={values.version}
            onChange={handleChange}
            onAddItem={handleAddition}
            onBlur={handleBlur}
          />
          <Form.Select
            required
            id="year"
            name="year"
            label="Year"
            options={yearOptions}
            error={
              errors.year.touched && errors.year.message
                ? errors.year.message
                : false
            }
            value={values.year}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>

        <Form.Button primary disabled={isFormInvalid} content={text} />
      </Form>
    </Segment>
  );
}

export default BookForm;
