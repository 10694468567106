import React from "react";
import Page from "../../components/Page";
import DayDetail from "../../components/Day/DayDetail";
import { Link, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { convertYearToColor } from "../../utils/helpers";
import { getBookTitle } from "../../utils/helpers";
import { Button, Dropdown } from "semantic-ui-react";
import {
  useDayState,
  actionMap,
  deleteDay,
  useDayDispatch
} from "../../contexts/day-context";
import { useBookState } from "../../contexts/book-context";
import ConfirmationButton from "../../components/ConfirmationButton";
import { useTaskState } from "../../contexts/task-context";
import { useWeekState } from "../../contexts/week-context";

function DayDetailPage() {
  const { url } = useRouteMatch();
  const { bookId, weekId, dayId } = useParams();

  const dispatch = useDayDispatch();
  const { map: dayMap, status } = useDayState();
  const { map: bookMap } = useBookState();
  const { map: weekMap } = useWeekState();
  const { map: taskMap } = useTaskState();

  const day = dayMap[dayId];
  if (!day) {
    return <Redirect to={`/book/${bookId}/week/${weekId}`} />;
  }

  const book = bookMap[bookId];
  const bookTitle = getBookTitle(book);
  const color = convertYearToColor(book.year);

  const week = weekMap[weekId];
  const weekTitle = `Week ${week.number}`;

  const tasksArr = Object.values(taskMap);
  const daysTasks = tasksArr.filter(t => day.tasks.indexOf(t._id) !== -1);

  const actions = (
    <Button.Group color="blue">
      <Button as={Link} to={`${url}/edit`}>
        Edit Day
      </Button>
      <Dropdown button className="button icon">
        <Dropdown.Menu>
          <ConfirmationButton
            trigger={<Dropdown.Item text="Delete Day" icon="trash" />}
            handleAction={() => {
              deleteDay(dispatch, dayId);
            }}
            triggerAction="hover"
            loading={status[actionMap.DELETE_DAY].isPending}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  );

  const crumbs = [
    {
      name: "Book",
      path: `/book/${bookId}`
    },
    {
      name: "Week",
      path: `/book/${bookId}/week/${weekId}`
    },
    {
      name: "Day"
    }
  ];

  return (
    <Page
      title={`Day ${day.number}`}
      subtitle={`${bookTitle} - ${weekTitle}`}
      color={color}
      icon="calendar outline"
      actions={actions}
      crumbs={crumbs}
    >
      <DayDetail day={day} tasks={daysTasks} />
    </Page>
  );
}

export default DayDetailPage;
