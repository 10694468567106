import React from "react";
import Page from "../../components/Page";
import DayForm from "../../components/Day/DayForm";
import { getBookTitle, getUniqueValues } from "../../utils/helpers";
import { isEmpty } from "lodash";
import { useLocation, Redirect } from "react-router-dom";
import { useWeekState } from "../../contexts/week-context";
import { useTaskState } from "../../contexts/task-context";
import { useBookState } from "../../contexts/book-context";
import { createDay, useDayDispatch } from "../../contexts/day-context";

function DayAddPage() {
  const dispatch = useDayDispatch();

  const { map: bookMap } = useBookState();
  const { map: weekMap } = useWeekState();
  const { map: taskMap } = useTaskState();

  const { state } = useLocation();
  const weekId = state ? state.weekId : undefined;

  const taskArr = Object.values(taskMap);

  if (isEmpty(weekMap)) {
    return <Redirect to="/book" />;
  }

  const week = weekId ? weekMap[weekId] : null;
  const bookId = week ? bookMap[week.book_id]._id : null;

  const weekArr = Object.values(weekMap);
  const weekOptions = weekArr
    .map(w => {
      return {
        key: w._id,
        value: w._id,
        text: `Week ${w.number}`,
        book_id: w.book_id
      };
    })
    .sort((a, b) => (a.text < b.text ? -1 : 1));

  const bookIdsWithWeeks = getUniqueValues(weekArr, "book_id");
  const bookArr = Object.values(bookMap);
  const bookOptions = bookArr
    .filter(b => bookIdsWithWeeks.indexOf(b._id) !== -1)
    .map(b => {
      return {
        key: b._id,
        value: b._id,
        text: getBookTitle(b)
      };
    });

  const crumbs = [
    {
      name: "Book",
      path: bookId ? `/book/${bookId}` : "/book"
    },
    {
      name: "Week",
      path: weekId ? `/book/${bookId}/week/${weekId}` : null
    },
    {
      name: "Day"
    }
  ];

  return (
    <Page title="Add Day" icon="plus" containerWidth="text" crumbs={crumbs}>
      <DayForm
        bookId={bookId}
        weekId={weekId}
        bookOptions={bookOptions}
        weekOptions={weekOptions}
        taskOptions={taskArr}
        dayAction={day => {
          createDay(dispatch, day);
        }}
      />
    </Page>
  );
}

export default DayAddPage;
