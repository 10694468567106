import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";

NavBreadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.object)
};

function NavBreadcrumbs({ crumbs }) {
  const crumbElements = crumbs.map((c, i, arr) => {
    if (i === arr.length - 1) {
      return (
        <Breadcrumb.Section
          key={c.name}
          as={c.path ? Link : "div"}
          to={c.path ? c.path : null}
          active={c.path ? false : true}
          link={c.path ? true : false}
        >
          {c.name}
        </Breadcrumb.Section>
      );
    }
    return (
      <React.Fragment key={c.name}>
        <Breadcrumb.Section
          as={c.path ? Link : "div"}
          to={c.path ? c.path : null}
          active={c.path ? false : true}
          link={c.path ? true : false}
        >
          {c.name}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
      </React.Fragment>
    );
  });
  return <Breadcrumb>{crumbElements}</Breadcrumb>;
}

export default NavBreadcrumbs;
