import React from "react";

const NotificationStateContext = React.createContext();
const NotificationDispatchContext = React.createContext();

/**
 * @const {string}
 */
const [ADD_NOTIFICATION, REMOVE_NOTIFICATION] = [
  "ADD_NOTIFICATION",
  "REMOVE_NOTIFICATION"
];

/**
 * Reducer function that takes in an action object, performs the action on the state, and returns the new state
 * @param {object} state - state object
 * @param {object} action - action object
 */
function notificationReducer(state, action) {
  const { notifications, lastId } = state;
  const { type, payload } = action;

  switch (type) {
    case ADD_NOTIFICATION: {
      const id = lastId + 1;
      return {
        notifications: [...notifications, { ...payload, id }],
        lastId: id
      };
    }
    case REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: notifications.filter(n => n.id !== payload)
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

// Action creators
function addNotification(dispatch, notification) {
  dispatch({ type: ADD_NOTIFICATION, payload: notification });
}

function removeNotification(dispatch, notificationId) {
  dispatch({ type: REMOVE_NOTIFICATION, payload: notificationId });
}

// Context Provider and consumer hooks
function NotificationProvider({ children }) {
  const [state, dispatch] = React.useReducer(notificationReducer, {
    notifications: [],
    lastId: 0
  });

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
}

function useNotificationState() {
  const context = React.useContext(NotificationStateContext);
  if (!context) {
    throw new Error(
      `useNotificationState must be used within a NotificationProvider`
    );
  }

  return context;
}

function useNotificationDispatch() {
  const context = React.useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationDispatch must be used within a NotificationProvider"
    );
  }

  return context;
}

export {
  useNotificationState,
  useNotificationDispatch,
  NotificationProvider,
  addNotification,
  removeNotification
};
