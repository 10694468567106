import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";
import { Form, Message, Segment, Header } from "semantic-ui-react";
import Editor from "../Editor";

import { useForm } from "../../hooks/form-hook";
import { required } from "../../utils/form-validators";
import { startCase } from "lodash";
import { actionMap, useDayState } from "../../contexts/day-context";

DayForm.propTypes = {
  day: PropTypes.shape({
    number: PropTypes.number,
    topic: PropTypes.string,
    week_id: PropTypes.string,
    devotion: PropTypes.string,
    reference: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.string)
  }),
  weekId: PropTypes.string,
  bookId: PropTypes.string,
  bookOptions: PropTypes.arrayOf(PropTypes.object),
  weekOptions: PropTypes.arrayOf(PropTypes.object),
  taskOptions: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.string,
  type: PropTypes.string,
  dayAction: PropTypes.func.isRequired
};

function DayForm({
  day,
  bookOptions,
  weekOptions: weekOptionsFromProps,
  taskOptions,
  weekId,
  bookId,
  text = "Add",
  type = actionMap.CREATE_DAY,
  dayAction
}) {
  const { status, error } = useDayState();

  const [selectedBook, setSelectedBook] = useState(
    bookId ? bookId : bookOptions[0].value
  );
  const [weekOptions, setWeekOptions] = useState(
    weekOptionsFromProps.filter(o => o.book_id === selectedBook)
  );

  const fields = {
    week_id: day ? day.week_id : weekId ? weekId : weekOptions[0].value,
    number: day ? day.number : 1,
    devotion: day ? day.devotion : "",
    reference: day ? day.reference : "",
    topic: day ? day.topic : "",
    tasks: day ? day.tasks : []
  };

  const validators = {
    week_id: [required],
    number: [required],
    devotion: [required],
    reference: [required],
    topic: [required]
  };

  const submitCallback = () => {
    dayAction({ ...values, number: parseInt(values.number, 10) });
  };

  function handleBookChange(e, { value }) {
    const newWeekOptions = weekOptionsFromProps.filter(
      o => o.book_id === value
    );
    setSelectedBook(value);
    setWeekOptions(newWeekOptions);
    handleChange(null, { name: "week_id", value: newWeekOptions[0].value });
  }

  function handleTaskChange(e, { value, checked }) {
    const task = taskOptions.filter(t => t._id === value)[0];

    if (checked) {
      setValues({ ...values, tasks: [...values.tasks, task._id] });
    } else {
      const newList = values.tasks.filter(t => t !== task._id);
      setValues({ ...values, tasks: newList });
    }
  }

  function handleEditorEvent(e, html, event) {
    const name = "devotion";
    const value = html;

    if (event === "change") {
      handleChange(e, { name, value });
    } else if (event === "blur") {
      handleBlur({
        target: {
          name,
          value
        }
      });
    }
  }

  const {
    values,
    setValues,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    formError,
    setFormError,
    isFormInvalid
  } = useForm(fields, submitCallback, validators);

  useEffect(() => {
    if (error) {
      setFormError({
        hasError: true,
        title: `${text} Failed`,
        message: error.message
      });
    }
  }, [setFormError, error, text]);

  return status[type].isSuccess ? (
    <Redirect
      to={`/book/${bookId ? bookId : selectedBook}/week/${values.week_id}`}
    />
  ) : (
    <Segment raised>
      <Form
        onSubmit={handleSubmit}
        loading={status[type].isPending}
        error={formError.hasError}
        noValidate
      >
        <Message error header={formError.title} content={formError.message} />

        <Header dividing size="small" content="Meta" color="orange" />
        <Form.Select
          id="bookId"
          label="Book"
          options={bookOptions}
          value={selectedBook}
          onChange={handleBookChange}
        />
        <Form.Group widths="equal">
          <Form.Select
            required
            id="weekId"
            name="week_id"
            label="Week"
            error={
              errors.week_id.touched && errors.week_id.message
                ? errors.week_id.message
                : false
            }
            options={weekOptions}
            value={values.week_id}
            onChange={handleChange}
          />
          <Form.Input
            width={4}
            required
            id="number"
            name="number"
            label="Day Number"
            type="number"
            min="1"
            error={
              errors.number.touched && errors.number.message
                ? errors.number.message
                : false
            }
            value={values.number}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>

        <Header dividing size="small" content="Day Info" color="orange" />
        <Form.Input
          required
          id="topic"
          name="topic"
          label="Topic"
          error={
            errors.topic.touched && errors.topic.message
              ? errors.topic.message
              : false
          }
          value={values.topic}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Form.Field
          required
          control={Editor}
          id="devotion"
          name="devotion"
          label="Devotion"
          error={
            errors.devotion.touched && errors.devotion.message
              ? errors.devotion.message
              : false
          }
          value={values.devotion}
          onChange={handleEditorEvent}
          onBlur={handleEditorEvent}
        />

        <Form.Input
          required
          id="reference"
          name="reference"
          label="Reference"
          error={
            errors.reference.touched && errors.reference.message
              ? errors.reference.message
              : false
          }
          value={values.reference}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Header dividing size="small" content="Tasks" color="orange" />
        {taskOptions.map(t => {
          const checked = values.tasks.indexOf(t._id) !== -1;
          return (
            <Form.Checkbox
              key={t._id}
              toggle
              id={`${t.name}Task`}
              value={t._id}
              checked={checked}
              onChange={handleTaskChange}
              label={`${startCase(t.name)} Task`}
            />
          );
        })}

        <Form.Button primary disabled={isFormInvalid} content={text} />
      </Form>
    </Segment>
  );
}

export default DayForm;
