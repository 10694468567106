import React, { useState } from "react";
import { useAuth } from "../../contexts/authentication-context";
import NewPasswordForm from "./NewPasswordForm";
import LoginForm from "./LoginForm";

function LoginFormContainer() {
  const [username, setUsername] = useState("");
  const {
    auth: { challenge }
  } = useAuth();

  return (
    <>
      {challenge ? (
        <NewPasswordForm username={username} />
      ) : (
        <LoginForm captureUsername={setUsername} />
      )}
    </>
  );
}

export default LoginFormContainer;
