import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Label, Icon, Button, Card } from "semantic-ui-react";
import { Link, useRouteMatch } from "react-router-dom";
import TaskCard from "../Task/TaskCard";
import stripTags from "striptags";

DayDetail.propTypes = {
  day: PropTypes.object.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired
};

function DayDetail({ day, tasks }) {
  const { topic, devotion, reference } = day;
  const { url } = useRouteMatch();
  return (
    <>
      <Segment raised as="section">
        <Label as="h2" attached="top">
          Day Info
        </Label>
        <Header size="medium">{topic}</Header>
        <p>{stripTags(devotion)}</p>
        <Label>
          <Icon name="bookmark" />
          {reference}
        </Label>
      </Segment>

      {tasks.length === 0 ? (
        <Segment placeholder raised>
          <Header icon>
            <Icon name="tasks" />
            No tasks are listed for this day.
          </Header>
          <Button as={Link} to={`${url}/edit`} primary>
            Add Task
          </Button>
        </Segment>
      ) : (
        <Segment raised as="section">
          <Label as="h2" attached="top" content="Tasks" />
          <Card.Group>
            {tasks.map(t => (
              <TaskCard task={t} key={t._id} />
            ))}
          </Card.Group>
        </Segment>
      )}
    </>
  );
}

export default DayDetail;
