import { startCase } from "lodash";

const required = (field, value) => {
  return value.length === 0 ? `${startCase(field)} is required` : "";
};

const strongPassword = (field, value) => {
  if (value.length === 0) {
    return "";
  }

  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
    ? ""
    : `${startCase(
        field
      )} must include at least one lowercase letter, uppercase letter, number, and special character`;
};

const minLength = (field, value, length) => {
  if (value.length === 0) {
    return "";
  }

  return value.length < length
    ? `${startCase(field)} must be ${length} chracters long`
    : "";
};

export { required, strongPassword, minLength };
