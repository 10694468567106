import http from "../utils/http";

function fetchBooks() {
  return http
    .get("/book")
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function createBook(payload) {
  return http
    .post("/book", payload)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function updateBook(bookId, payload) {
  const config = {
    params: { book_id: bookId }
  };
  return http
    .patch("/book", payload, config)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function deleteBook(bookId) {
  const config = {
    params: { book_id: bookId }
  };
  return http
    .delete("/book", config)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

export { fetchBooks, createBook, updateBook, deleteBook };
