import React from "react";
import * as bookClient from "../clients/book-client";
import { statusMap, generateStatusObject } from "../utils/status";
import { makeMap } from "../utils/helpers";

const BookStateContext = React.createContext();
const BookDispatchContext = React.createContext();

/**
 * @const {string}
 */
const [FETCH_BOOKS, CREATE_BOOK, UPDATE_BOOK, DELETE_BOOK] = [
  "FETCH_BOOKS",
  "CREATE_BOOK",
  "UPDATE_BOOK",
  "DELETE_BOOK"
];

/**
 * @const {Object}
 */
const actionMap = {
  FETCH_BOOKS,
  CREATE_BOOK,
  UPDATE_BOOK,
  DELETE_BOOK
};

/**
 * Reducer function that takes in an action object, performs the action on the state, and returns the new state
 * @param {object} state - state object
 * @param {object} action - action object
 */
function BookReducer(state, action) {
  const { map, status } = state;
  const { type, payload, meta, error } = action;

  // Handle error
  if (error) {
    return {
      ...state,
      error: payload,
      status: {
        ...status,
        [type]: generateStatusObject(statusMap.ERROR)
      }
    };
  }

  // Dispatch has completed
  if (meta && meta.complete) {
    return {
      ...state,
      error: null,
      status: {
        ...status,
        [type]: generateStatusObject()
      }
    };
  }

  // No payload, so we are making a request
  if (!payload) {
    return {
      ...state,
      error: null,
      status: {
        ...status,
        [type]: generateStatusObject(statusMap.PENDING)
      }
    };
  }

  // Handle payloads for each action
  switch (type) {
    case FETCH_BOOKS: {
      return {
        ...state,
        error: null,
        map: makeMap(payload),
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    case CREATE_BOOK: {
      return {
        ...state,
        error: null,
        map: { ...map, [payload._id]: payload },
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    case UPDATE_BOOK: {
      return {
        ...state,
        error: null,
        map: { ...map, [payload._id]: payload },
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    case DELETE_BOOK: {
      const mapCopy = { ...map };
      delete mapCopy[payload];
      return {
        ...state,
        error: null,
        map: mapCopy,
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

// Action creators
async function fetchBooks(dispatch) {
  dispatch({ type: FETCH_BOOKS });
  try {
    const books = await bookClient.fetchBooks();
    dispatch({ type: FETCH_BOOKS, payload: books });
  } catch (error) {
    dispatch({ type: FETCH_BOOKS, payload: error, error: true });
  } finally {
    dispatch({ type: FETCH_BOOKS, meta: { complete: true } });
  }
}

async function createBook(dispatch, book) {
  dispatch({ type: CREATE_BOOK });
  try {
    const newBook = await bookClient.createBook(book);
    dispatch({ type: CREATE_BOOK, payload: newBook[0] });
  } catch (error) {
    dispatch({ type: CREATE_BOOK, payload: error, error: true });
  } finally {
    dispatch({ type: CREATE_BOOK, meta: { complete: true } });
  }
}

async function updateBook(dispatch, bookId, updates) {
  dispatch({ type: UPDATE_BOOK });
  try {
    const updatedBook = await bookClient.updateBook(bookId, updates);
    dispatch({ type: UPDATE_BOOK, payload: updatedBook.value });
  } catch (error) {
    dispatch({ type: UPDATE_BOOK, payload: error, error: true });
  } finally {
    dispatch({ type: UPDATE_BOOK, meta: { complete: true } });
  }
}

async function deleteBook(dispatch, bookId) {
  dispatch({ type: DELETE_BOOK });
  try {
    await bookClient.deleteBook(bookId);
    dispatch({ type: DELETE_BOOK, payload: bookId });
  } catch (error) {
    dispatch({ type: DELETE_BOOK, payload: error, error: true });
  } finally {
    dispatch({ type: DELETE_BOOK, meta: { complete: true } });
  }
}

// Book Provider and consumer hooks
function BookProvider({ children }) {
  const initialStatus = generateStatusObject();

  const [state, dispatch] = React.useReducer(BookReducer, {
    map: {},
    status: {
      [FETCH_BOOKS]: initialStatus,
      [CREATE_BOOK]: initialStatus,
      [UPDATE_BOOK]: initialStatus,
      [DELETE_BOOK]: initialStatus
    }
  });

  return (
    <BookStateContext.Provider value={state}>
      <BookDispatchContext.Provider value={dispatch}>
        {children}
      </BookDispatchContext.Provider>
    </BookStateContext.Provider>
  );
}

function useBookState() {
  const context = React.useContext(BookStateContext);
  if (!context) {
    throw new Error(`useBookState must be used within a BookProvider`);
  }

  return context;
}

function useBookDispatch() {
  const context = React.useContext(BookDispatchContext);
  if (context === undefined) {
    throw new Error("useBookDispatch must be used within a BookProvider");
  }

  return context;
}

export {
  useBookState,
  useBookDispatch,
  BookProvider,
  actionMap,
  fetchBooks,
  createBook,
  updateBook,
  deleteBook
};
