import React from "react";
import * as taskClient from "../clients/task-client";
import { statusMap, generateStatusObject } from "../utils/status";
import { makeMap } from "../utils/helpers";

const TaskStateContext = React.createContext();
const TaskDispatchContext = React.createContext();

/**
 * @const {string}
 */
const FETCH_TASKS = "FETCH_TASKS";
// const [FETCH_TASKS, CREATE_TASK, UPDATE_TASK, DELETE_TASK] = [
//   "FETCH_TASKS",
//   "CREATE_TASK",
//   "UPDATE_TASK",
//   "DELETE_TASK"
// ];

/**
 * @const {Object}
 */
const actionMap = {
  FETCH_TASKS
  // CREATE_TASK,
  // UPDATE_TASK,
  // DELETE_TASK
};

/**
 * Reducer function that takes in an action object, performs the action on the state, and returns the new state
 * @param {object} state - state object
 * @param {object} action - action object
 */
function TaskReducer(state, action) {
  const { status } = state;
  const { type, payload, meta, error } = action;

  // Handle error
  if (error) {
    return {
      ...state,
      error: payload,
      status: {
        ...status,
        [type]: generateStatusObject(statusMap.ERROR)
      }
    };
  }

  // Dispatch has completed
  if (meta && meta.complete) {
    return {
      ...state,
      error: null,
      status: {
        ...status,
        [type]: generateStatusObject()
      }
    };
  }

  // No payload, so we are making a request
  if (!payload) {
    return {
      ...state,
      error: null,
      status: {
        ...status,
        [type]: generateStatusObject(statusMap.PENDING)
      }
    };
  }

  // Handle payloads for each action
  switch (type) {
    case FETCH_TASKS: {
      return {
        ...state,
        error: null,
        map: makeMap(payload),
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    // case CREATE_TASK: {
    //   return {
    //     ...state,
    //     error: null,
    //     map: { ...map, [payload._id]: payload },
    //     status: {
    //       ...status,
    //       [type]: generateStatusObject(statusMap.SUCCESS)
    //     }
    //   };
    // }

    // case UPDATE_TASK: {
    //   return {
    //     ...state,
    //     error: null,
    //     map: { ...map, [payload._id]: payload },
    //     status: {
    //       ...status,
    //       [type]: generateStatusObject(statusMap.SUCCESS)
    //     }
    //   };
    // }

    // case DELETE_TASK: {
    //   const mapCopy = { ...map };
    //   delete mapCopy[payload];
    //   return {
    //     ...state,
    //     error: null,
    //     map: mapCopy,
    //     status: {
    //       ...status,
    //       [type]: generateStatusObject(statusMap.SUCCESS)
    //     }
    //   };
    // }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

// Action creators
async function fetchTasks(dispatch) {
  dispatch({ type: FETCH_TASKS });
  try {
    const tasks = await taskClient.fetchTasks();
    dispatch({ type: FETCH_TASKS, payload: tasks });
  } catch (error) {
    dispatch({ type: FETCH_TASKS, payload: error, error: true });
  } finally {
    dispatch({ type: FETCH_TASKS, meta: { complete: true } });
  }
}

// async function createTask(dispatch, task) {
//   dispatch({ type: CREATE_TASK });
//   try {
//     const newTask = await taskClient.createTask(task);
//     dispatch({ type: CREATE_TASK, payload: newTask[0] });
//   } catch (error) {
//     dispatch({ type: CREATE_TASK, payload: error, error: true });
//   } finally {
//     dispatch({ type: CREATE_TASK, meta: { complete: true } });
//   }
// }

// async function updateTask(dispatch, taskId, updates) {
//   dispatch({ type: UPDATE_TASK });
//   try {
//     const updatedTask = await taskClient.updateTask(taskId, updates);
//     dispatch({ type: UPDATE_TASK, payload: updatedTask.value });
//   } catch (error) {
//     dispatch({ type: UPDATE_TASK, payload: error, error: true });
//   } finally {
//     dispatch({ type: UPDATE_TASK, meta: { complete: true } });
//   }
// }

// async function deleteTask(dispatch, taskId) {
//   dispatch({ type: DELETE_TASK });
//   try {
//     await taskClient.deleteTask(taskId);
//     dispatch({ type: DELETE_TASK, payload: taskId });
//   } catch (error) {
//     dispatch({ type: DELETE_TASK, payload: error, error: true });
//   } finally {
//     dispatch({ type: DELETE_TASK, meta: { complete: true } });
//   }
// }

// Task Provider and consumer hooks
function TaskProvider({ children }) {
  const initialStatus = generateStatusObject();

  const [state, dispatch] = React.useReducer(TaskReducer, {
    map: {},
    status: {
      [FETCH_TASKS]: initialStatus
      // [CREATE_TASK]: initialStatus,
      // [UPDATE_TASK]: initialStatus,
      // [DELETE_TASK]: initialStatus
    }
  });

  return (
    <TaskStateContext.Provider value={state}>
      <TaskDispatchContext.Provider value={dispatch}>
        {children}
      </TaskDispatchContext.Provider>
    </TaskStateContext.Provider>
  );
}

function useTaskState() {
  const context = React.useContext(TaskStateContext);
  if (!context) {
    throw new Error(`useTaskState must be used within a TaskProvider`);
  }

  return context;
}

function useTaskDispatch() {
  const context = React.useContext(TaskDispatchContext);
  if (context === undefined) {
    throw new Error("useTaskDispatch must be used within a TaskProvider");
  }

  return context;
}

export {
  useTaskState,
  useTaskDispatch,
  TaskProvider,
  actionMap,
  fetchTasks
  // createTask,
  // updateTask,
  // deleteTask
};
