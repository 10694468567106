import React from "react";
import Page from "../../components/Page";
import BookForm from "../../components/Book/BookForm";
import { getUniqueValues } from "../../utils/helpers";
import { startCase } from "lodash";
import {
  useBookState,
  useBookDispatch,
  createBook
} from "../../contexts/book-context";

function BookAddPage() {
  const dispatch = useBookDispatch();
  const { map } = useBookState();
  const booksArr = Object.values(map);

  const yearOptions = getUniqueValues(booksArr, "year").map(y => {
    return {
      key: y,
      value: y,
      text: startCase(y)
    };
  });

  const versionOptions = getUniqueValues(booksArr, "version").map(v => {
    return {
      key: v,
      value: v,
      text: v.toUpperCase()
    };
  });

  const crumbs = [
    {
      name: "Book",
      path: "/book"
    }
  ];

  return (
    <Page title="Add Book" icon="plus" containerWidth="text" crumbs={crumbs}>
      <BookForm
        yearOptions={yearOptions}
        versionOptions={versionOptions}
        bookAction={book => {
          createBook(dispatch, book);
        }}
      />
    </Page>
  );
}

export default BookAddPage;
