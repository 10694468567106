import React from "react";
import PropTypes from "prop-types";
import { convertYearToColor } from "../../utils/helpers";
import { Card, Button, Icon } from "semantic-ui-react";
import { Link, useRouteMatch } from "react-router-dom";
import ConfirmationButton from "../ConfirmationButton";
import {
  useBookDispatch,
  useBookState,
  actionMap,
  deleteBook
} from "../../contexts/book-context";

BookCard.propTypes = {
  book: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }).isRequired
};

function BookCard({ book }) {
  const { program, year, version, _id } = book;
  const color = convertYearToColor(year);
  const { url } = useRouteMatch();

  const bookDispatch = useBookDispatch();
  const { status } = useBookState();

  function handleDelete() {
    deleteBook(bookDispatch, _id);
  }

  const deleteButton = (
    <Button animated="vertical">
      <Button.Content hidden>Delete</Button.Content>
      <Button.Content visible>
        <Icon name="trash" />
      </Button.Content>
    </Button>
  );

  return (
    <Card color={color}>
      <Card.Content>
        <Card.Header>{program}</Card.Header>
        <Card.Meta>{version.toUpperCase()}</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Button as={Link} to={`${url}/${_id}`} primary compact>
          View
        </Button>
        <Button.Group floated="right" compact>
          <Button as={Link} to={`${url}/${_id}/edit`} animated="vertical">
            <Button.Content hidden>Edit</Button.Content>
            <Button.Content visible>
              <Icon name="edit" />
            </Button.Content>
          </Button>
          <ConfirmationButton
            trigger={deleteButton}
            handleAction={handleDelete}
            loading={status[actionMap.DELETE_BOOK].isPending}
          />
        </Button.Group>
      </Card.Content>
    </Card>
  );
}

export default BookCard;
