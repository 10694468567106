import React from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import { Item, Icon, Button, Label } from "semantic-ui-react";
import ConfirmationButton from "../ConfirmationButton";
import {
  useWeekDispatch,
  useWeekState,
  actionMap,
  deleteWeek
} from "../../contexts/week-context";

WeekItem.propTypes = {
  week: PropTypes.object
};

function WeekItem({ week }) {
  const { _id, number, question, reference, topic, verse } = week;
  const dispatch = useWeekDispatch();
  const { status } = useWeekState();
  const { url } = useRouteMatch();

  const deleteButton = (
    <Button animated="vertical">
      <Button.Content hidden>Delete</Button.Content>
      <Button.Content visible>
        <Icon name="trash" />
      </Button.Content>
    </Button>
  );

  return (
    <Item>
      <Item.Content>
        <Item.Header>{topic}</Item.Header>
        <Item.Meta>
          <Label>
            <Icon name="calendar alternate outline" /> Week {number}
          </Label>
        </Item.Meta>
        <Item.Description>{question}</Item.Description>
        <Item.Description>{verse}</Item.Description>
        <Item.Extra>
          <Icon name="bookmark" />
          {reference}
        </Item.Extra>
        <Item.Extra>
          <Button compact primary as={Link} to={`${url}/week/${_id}`}>
            View
          </Button>
          <Button.Group compact floated="right">
            <Button
              as={Link}
              to={`${url}/week/${_id}/edit`}
              animated="vertical"
            >
              <Button.Content hidden>Edit</Button.Content>
              <Button.Content visible>
                <Icon name="edit" />
              </Button.Content>
            </Button>
            <ConfirmationButton
              trigger={deleteButton}
              handleAction={() => {
                deleteWeek(dispatch, _id);
              }}
              loading={status[actionMap.DELETE_WEEK].isPending}
            />
          </Button.Group>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
}

export default WeekItem;
