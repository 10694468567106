import React from "react";
import LoginForms from "../components/Login";
import Page from "../components/Page";

function LoginPage() {
  return (
    <Page title="Login" containerWidth="text">
      <LoginForms />
    </Page>
  );
}

export default LoginPage;
