import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Icon,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { useAuth } from "../../contexts/authentication-context";
import { useForm } from "../../hooks/form-hook";
import {
  required,
  strongPassword,
  minLength
} from "../../utils/form-validators";

NewPasswordForm.propTypes = {
  username: PropTypes.string.isRequired
};

function NewPasswordForm({ username }) {
  const [showPassword, setShowPassword] = useState(false);

  const {
    logout,
    login,
    auth: { challenge: challengeName, session }
  } = useAuth();

  const validators = {
    newPassword: [
      (field, value) => minLength(field, value, 10),
      strongPassword,
      required
    ]
  };

  const fields = {
    newPassword: ""
  };

  const submitCallback = () => {
    const { newPassword } = values;
    login({ username, newPassword, challengeName, session }).catch(err => {
      setFormError({
        status: true,
        title: "Setting New Password Failed",
        message: err.message
      });
      setIsSubmitting(false);
    });
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    formError,
    setFormError,
    isFormInvalid
  } = useForm(fields, submitCallback, validators);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleCancel() {
    logout();
  }

  return (
    <Segment raised>
      <Header as="h2">Set New Password</Header>
      <Form
        noValidate
        error={formError.hasError}
        loading={isSubmitting}
        onSubmit={handleSubmit}
      >
        <Message error header={formError.title} content={formError.message} />
        <Form.Input
          type={showPassword ? "text" : "password"}
          id="newPassword"
          name="newPassword"
          label="New Password"
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.newPassword.touched && errors.newPassword.message
              ? errors.newPassword.message
              : false
          }
          required
        />
        <Form.Checkbox
          id="showPassword"
          label="Show password"
          onClick={toggleShowPassword}
        />

        <div>
          <Button primary animated disabled={isFormInvalid}>
            <Button.Content visible>Set Password</Button.Content>
            <Button.Content hidden>
              <Icon name="lock" />
            </Button.Content>
          </Button>

          <Button basic type="button" content="Cancel" onClick={handleCancel} />
        </div>
      </Form>
    </Segment>
  );
}

export default NewPasswordForm;
