import React from "react";
import * as weekClient from "../clients/week-client";
import { statusMap, generateStatusObject } from "../utils/status";
import { makeMap } from "../utils/helpers";

const WeekStateContext = React.createContext();
const WeekDispatchContext = React.createContext();

/**
 * @const {string}
 */
const [FETCH_WEEKS, CREATE_WEEK, UPDATE_WEEK, DELETE_WEEK] = [
  "FETCH_WEEKS",
  "CREATE_WEEK",
  "UPDATE_WEEK",
  "DELETE_WEEK"
];

/**
 * @const {Object}
 */
const actionMap = {
  FETCH_WEEKS,
  CREATE_WEEK,
  UPDATE_WEEK,
  DELETE_WEEK
};

/**
 * Reducer function that takes in an action object, performs the action on the state, and returns the new state
 * @param {object} state - state object
 * @param {object} action - action object
 */
function WeekReducer(state, action) {
  const { map, status } = state;
  const { type, payload, meta, error } = action;

  // Handle error
  if (error) {
    return {
      ...state,
      error: payload,
      status: {
        ...status,
        [type]: generateStatusObject(statusMap.ERROR)
      }
    };
  }

  // Dispatch has completed
  if (meta && meta.complete) {
    return {
      ...state,
      error: null,
      status: {
        ...status,
        [type]: generateStatusObject()
      }
    };
  }

  // No payload, so we are making a request
  if (!payload) {
    return {
      ...state,
      error: null,
      status: {
        ...status,
        [type]: generateStatusObject(statusMap.PENDING)
      }
    };
  }

  // Handle payloads for each action
  switch (type) {
    case FETCH_WEEKS: {
      return {
        ...state,
        error: null,
        map: makeMap(payload),
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    case CREATE_WEEK: {
      return {
        ...state,
        error: null,
        map: { ...map, [payload._id]: payload },
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    case UPDATE_WEEK: {
      return {
        ...state,
        error: null,
        map: { ...map, [payload._id]: payload },
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    case DELETE_WEEK: {
      const mapCopy = { ...map };
      delete mapCopy[payload];
      return {
        ...state,
        error: null,
        map: mapCopy,
        status: {
          ...status,
          [type]: generateStatusObject(statusMap.SUCCESS)
        }
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

// Action creators
async function fetchWeeks(dispatch) {
  dispatch({ type: FETCH_WEEKS });
  try {
    const weeks = await weekClient.fetchWeeks();
    dispatch({ type: FETCH_WEEKS, payload: weeks });
  } catch (error) {
    dispatch({ type: FETCH_WEEKS, payload: error, error: true });
  } finally {
    dispatch({ type: FETCH_WEEKS, meta: { complete: true } });
  }
}

async function createWeek(dispatch, week) {
  dispatch({ type: CREATE_WEEK });
  try {
    const newWeek = await weekClient.createWeek(week);
    dispatch({ type: CREATE_WEEK, payload: newWeek[0] });
  } catch (error) {
    dispatch({ type: CREATE_WEEK, payload: error, error: true });
  } finally {
    dispatch({ type: CREATE_WEEK, meta: { complete: true } });
  }
}

async function updateWeek(dispatch, weekId, updates) {
  dispatch({ type: UPDATE_WEEK });
  try {
    const updatedWeek = await weekClient.updateWeek(weekId, updates);
    dispatch({ type: UPDATE_WEEK, payload: updatedWeek.value });
  } catch (error) {
    dispatch({ type: UPDATE_WEEK, payload: error, error: true });
  } finally {
    dispatch({ type: UPDATE_WEEK, meta: { complete: true } });
  }
}

async function deleteWeek(dispatch, weekId) {
  dispatch({ type: DELETE_WEEK });
  try {
    await weekClient.deleteWeek(weekId);
    dispatch({ type: DELETE_WEEK, payload: weekId });
  } catch (error) {
    dispatch({ type: DELETE_WEEK, payload: error, error: true });
  } finally {
    dispatch({ type: DELETE_WEEK, meta: { complete: true } });
  }
}

// Week Provider and consumer hooks
function WeekProvider({ children }) {
  const initialStatus = generateStatusObject();

  const [state, dispatch] = React.useReducer(WeekReducer, {
    map: {},
    status: {
      [FETCH_WEEKS]: initialStatus,
      [CREATE_WEEK]: initialStatus,
      [UPDATE_WEEK]: initialStatus,
      [DELETE_WEEK]: initialStatus
    }
  });

  return (
    <WeekStateContext.Provider value={state}>
      <WeekDispatchContext.Provider value={dispatch}>
        {children}
      </WeekDispatchContext.Provider>
    </WeekStateContext.Provider>
  );
}

function useWeekState() {
  const context = React.useContext(WeekStateContext);
  if (!context) {
    throw new Error(`useWeekState must be used within a WeekProvider`);
  }

  return context;
}

function useWeekDispatch() {
  const context = React.useContext(WeekDispatchContext);
  if (context === undefined) {
    throw new Error("useWeekDispatch must be used within a WeekProvider");
  }

  return context;
}

export {
  useWeekState,
  useWeekDispatch,
  WeekProvider,
  actionMap,
  fetchWeeks,
  createWeek,
  updateWeek,
  deleteWeek
};
