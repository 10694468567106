import React from "react";
import { Redirect, useParams } from "react-router-dom";
import Page from "../../components/Page";
import WeekForm from "../../components/Week/WeekForm";
import { startCase } from "lodash";
import { useBookState } from "../../contexts/book-context";
import {
  useWeekState,
  useWeekDispatch,
  updateWeek,
  actionMap
} from "../../contexts/week-context";

function WeekEditPage() {
  const { weekId, bookId } = useParams();
  const { map: weekMap } = useWeekState();
  const dispatch = useWeekDispatch();
  const week = weekMap[weekId];

  const { map: bookMap } = useBookState();

  if (!week) {
    return <Redirect to={`/book/${bookId}`} />;
  }

  const weeksArr = Object.values(weekMap);
  const doctrineOptions = weeksArr
    .reduce((unique, w) => {
      if (unique.indexOf(w.doctrine_name) === -1) {
        unique.push(w.doctrine_name);
      }
      return unique;
    }, [])
    .map(doctrine => ({ text: doctrine, value: doctrine }));

  const booksArr = Object.values(bookMap);
  const bookOptions = booksArr.map(b => {
    return {
      key: b._id,
      value: b._id,
      text: `${startCase(b.year)} - ${b.program} (${b.version.toUpperCase()})`
    };
  });

  const crumbs = [
    {
      name: "Book",
      path: `/book/${bookId}`
    },
    {
      name: "Week"
    }
  ];

  return (
    <Page title="Edit Week" icon="edit" containerWidth="text" crumbs={crumbs}>
      <WeekForm
        bookId={bookId}
        week={week}
        doctrineOptions={doctrineOptions}
        bookOptions={bookOptions}
        text="Update"
        type={actionMap.UPDATE_WEEK}
        weekAction={updates => {
          updateWeek(dispatch, weekId, updates);
        }}
      />
    </Page>
  );
}

export default WeekEditPage;
