import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Label, Icon, Button, Item } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DayItem from "../Day/DayItem";

WeekDetail.propTypes = {
  week: PropTypes.object.isRequired,
  days: PropTypes.arrayOf(PropTypes.object)
};

function WeekDetail({ week, days }) {
  const { topic, question, verse, reference } = week;
  return (
    <>
      <Segment raised as="section">
        <Label as="h2" attached="top">
          Week Info
        </Label>
        <Header size="medium">{topic}</Header>
        <p>{question}</p>
        <p>{verse}</p>
        <Label>
          <Icon name="bookmark" />
          {reference}
        </Label>
      </Segment>

      {days.length === 0 ? (
        <Segment placeholder raised>
          <Header icon>
            <Icon name="calendar outline" />
            No days are listed for this week.
          </Header>
          <Button
            as={Link}
            to={{ pathname: "/day/add", state: { weekId: week._id } }}
            primary
          >
            Add Day
          </Button>
        </Segment>
      ) : (
        <Segment raised as="section">
          <Label as="h2" attached="top" content="Days" />
          <Item.Group divided>
            {days.map(d => (
              <DayItem day={d} key={d._id} />
            ))}
          </Item.Group>
        </Segment>
      )}
    </>
  );
}

export default WeekDetail;
