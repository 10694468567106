import { startCase } from "lodash";

/**
 * Produces a map of ids -> object from an array of objects
 * @param {Object[]} arr - array of objects from which to create a map
 */
export function makeMap(arr) {
  return arr.reduce((map, item) => {
    map[item._id] = item;
    return map;
  }, {});
}

/**
 * Returns a formatted string for a given book title
 * @param {Object} book - book object returned from api
 * @return {string} formatted book title
 */
export function getBookTitle(book) {
  return `${startCase(book.year)} - ${
    book.program
  } (${book.version.toUpperCase()})`;
}

/**
 * Returns an array of unique values of a specific property shared across objects
 * @param {Object[]} arr  - Array of objects
 * @param {string}   prop - property, from which to find unique values
 *
 * @return {Array} array of unique values
 */
export function getUniqueValues(arr, prop) {
  return arr.reduce((unique, o) => {
    if (unique.indexOf(o[prop]) === -1) {
      unique.push(o[prop]);
    }
    return unique;
  }, []);
}

export function convertYearToColor(year) {
  const VALID_COLORS = [
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "teal",
    "blue",
    "violet",
    "purple",
    "pink",
    "brown",
    "grey",
    "black"
  ];
  const isYearValidColor = VALID_COLORS.indexOf(year) !== -1;

  return isYearValidColor ? year : "grey";
}
