import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popup, Button } from "semantic-ui-react";

ConfirmationButton.propTypes = {
  trigger: PropTypes.element.isRequired,
  handleAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  triggerAction: PropTypes.string
};

function ConfirmationButton({
  handleAction,
  trigger,
  loading,
  triggerAction = "click"
}) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  const confirm = (
    <Button.Group compact>
      <Button
        onClick={handleAction}
        negative
        content="Confirm"
        loading={loading}
      />
      <Button content="Cancel" onClick={handleClose} />
    </Button.Group>
  );

  return (
    <Popup
      trigger={trigger}
      header="Are you sure?"
      content={confirm}
      on={triggerAction}
      hoverable
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
}

export default ConfirmationButton;
