import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "../Layout";
import SplashScreen from "../SplashScreen";

import {
  useBookState,
  useBookDispatch,
  actionMap as bookActionMap,
  fetchBooks
} from "../../contexts/book-context";

import {
  useWeekState,
  useWeekDispatch,
  actionMap as weekActionMap,
  fetchWeeks
} from "../../contexts/week-context";

import {
  useDayState,
  useDayDispatch,
  fetchDays,
  actionMap as dayActionMap
} from "../../contexts/day-context";

import {
  useTaskState,
  useTaskDispatch,
  fetchTasks,
  actionMap as taskActionMap
} from "../../contexts/task-context";

import { useNotificationDispatch } from "../../contexts/notification-context";
import {
  handleBookNotifications,
  handleWeekNotifications,
  handleDayNotifications,
  handleTaskNotifications
} from "../../utils/notification";

// Book pages
import BookListPage from "../../pages/book/book-list";
import BookAddPage from "../../pages/book/book-add";
import BookEditPage from "../../pages/book/book-edit";
import BookDetailPage from "../../pages/book/book-detail";

// Week pages
import WeekAddPage from "../../pages/week/week-add";
import WeekDetailPage from "../../pages/week/week-detail";
import WeekEditPage from "../../pages/week/week-edit";

// Day pages
import DayAddPage from "../../pages/day/day-add";
import DayDetailPage from "../../pages/day/day-detail";
import DayEditPage from "../../pages/day/day-edit";

function AuthenticatedApp() {
  const { status: bookStatus } = useBookState();
  const { status: weekStatus } = useWeekState();
  const { status: dayStatus } = useDayState();
  const { status: taskStatus } = useTaskState();

  // Fetching app data
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const bookDispatch = useBookDispatch();
  const weekDispatch = useWeekDispatch();
  const dayDispatch = useDayDispatch();
  const taskDispatch = useTaskDispatch();
  useEffect(() => {
    fetchBooks(bookDispatch);
    fetchWeeks(weekDispatch);
    fetchDays(dayDispatch);
    fetchTasks(taskDispatch);

    setIsInitialLoad(false);
  }, [bookDispatch, weekDispatch, dayDispatch, taskDispatch]);

  // Notification dispatching
  const notificationDispatch = useNotificationDispatch();
  useEffect(() => {
    handleBookNotifications(notificationDispatch, bookStatus);
  }, [notificationDispatch, bookStatus]);
  useEffect(() => {
    handleWeekNotifications(notificationDispatch, weekStatus);
  }, [notificationDispatch, weekStatus]);
  useEffect(() => {
    handleDayNotifications(notificationDispatch, dayStatus);
  }, [notificationDispatch, dayStatus]);
  useEffect(() => {
    handleTaskNotifications(notificationDispatch, taskStatus);
  }, [notificationDispatch, taskStatus]);

  // Handling loading state
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    if (!isInitialLoad) {
      const isDataLoading = [
        bookStatus[bookActionMap.FETCH_BOOKS],
        weekStatus[weekActionMap.FETCH_WEEKS],
        dayStatus[dayActionMap.FETCH_DAYS],
        taskStatus[taskActionMap.FETCH_TASKS]
      ].some(s => s.isPending);

      setIsFetching(isDataLoading);
    }
  }, [isInitialLoad, bookStatus, weekStatus, dayStatus, taskStatus]);

  return (
    <Layout>
      {isFetching ? (
        <SplashScreen status="Getting everything set up..." />
      ) : (
        <Switch>
          <Route
            path="/book/:bookId/week/:weekId/day/:dayId/edit"
            component={DayEditPage}
          />
          <Route
            path="/book/:bookId/week/:weekId/day/:dayId"
            component={DayDetailPage}
          />
          <Route path="/day/add" component={DayAddPage} />

          <Route
            path="/book/:bookId/week/:weekId/edit"
            component={WeekEditPage}
          />
          <Route path="/book/:bookId/week/:weekId" component={WeekDetailPage} />
          <Route path="/week/add" component={WeekAddPage} />

          <Route path="/book/:bookId/edit" component={BookEditPage} />
          <Route exact path="/book/add" component={BookAddPage} />
          <Route path="/book/:bookId" component={BookDetailPage} />
          <Route path="/book" component={BookListPage} />

          <Route path="/">
            <Redirect to="/book" />
          </Route>
        </Switch>
      )}
    </Layout>
  );
}

export default AuthenticatedApp;
