import React from "react";
import { Menu, Segment, Icon, Button, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logos/tt-logo.svg";

import { useAuth } from "../contexts/authentication-context";

function AppBar() {
  const { isAuthenticated, logout } = useAuth();

  return (
    <Segment>
      <Menu secondary borderless>
        <Menu.Item fitted="vertically">
          <Link to="/">
            <Logo style={{ height: "56px" }} />
          </Link>
        </Menu.Item>
        {isAuthenticated && (
          <Menu.Menu position="right">
            <Menu.Item>
              <Dropdown text="Add" icon="plus">
                <Dropdown.Menu>
                  <Dropdown.Item text="Book" as={Link} to="/book/add" />
                  <Dropdown.Item text="Week" as={Link} to="/week/add" />
                  <Dropdown.Item text="Day" as={Link} to="/day/add" />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            <Menu.Item>
              <Button animated onClick={logout}>
                <Button.Content visible>Logout</Button.Content>
                <Button.Content hidden>
                  <Icon name="sign-out" />
                </Button.Content>
              </Button>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
    </Segment>
  );
}

export default AppBar;
