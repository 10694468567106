import React from "react";
import { addNotification } from "../contexts/notification-context";
import { actionMap as bookActionMap } from "../contexts/book-context";
import { actionMap as weekActionMap } from "../contexts/week-context";
import { actionMap as dayActionMap } from "../contexts/day-context";
import { actionMap as taskActionMap } from "../contexts/task-context";
import { Button } from "semantic-ui-react";

const reloadAction = (
  <Button
    inverted
    basic
    compact
    onClick={() => {
      window.location.reload(false);
    }}
    content="Try Again"
  />
);

export function handleBookNotifications(dispatch, status) {
  if (status[bookActionMap.FETCH_BOOKS].isError) {
    addNotification(dispatch, {
      message: "Fetching books failed.",
      dismissable: true,
      action: reloadAction
    });
  }

  if (status[bookActionMap.CREATE_BOOK].isSuccess) {
    addNotification(dispatch, { message: "Book created." });
  }
  if (status[bookActionMap.CREATE_BOOK].isError) {
    addNotification(dispatch, {
      message: "Book creation failed."
    });
  }

  if (status[bookActionMap.UPDATE_BOOK].isSuccess) {
    addNotification(dispatch, { message: "Book updated." });
  }
  if (status[bookActionMap.UPDATE_BOOK].isError) {
    addNotification(dispatch, { message: "Book update failed." });
  }

  if (status[bookActionMap.DELETE_BOOK].isSuccess) {
    addNotification(dispatch, { message: "Book deleted." });
  }
  if (status[bookActionMap.DELETE_BOOK].isError) {
    addNotification(dispatch, {
      message: "Book deletion failed."
    });
  }
}

export function handleWeekNotifications(dispatch, status) {
  if (status[weekActionMap.FETCH_WEEKS].isError) {
    addNotification(dispatch, {
      message: "Fetching weeks failed.",
      dismissable: true,
      action: reloadAction
    });
  }

  if (status[weekActionMap.CREATE_WEEK].isSuccess) {
    addNotification(dispatch, { message: "Week created." });
  }
  if (status[weekActionMap.CREATE_WEEK].isError) {
    addNotification(dispatch, {
      message: "Week creation failed."
    });
  }

  if (status[weekActionMap.UPDATE_WEEK].isSuccess) {
    addNotification(dispatch, { message: "Week updated." });
  }
  if (status[weekActionMap.UPDATE_WEEK].isError) {
    addNotification(dispatch, { message: "Week update failed." });
  }

  if (status[weekActionMap.DELETE_WEEK].isSuccess) {
    addNotification(dispatch, { message: "Week deleted." });
  }
  if (status[weekActionMap.DELETE_WEEK].isError) {
    addNotification(dispatch, {
      message: "Week deletion failed."
    });
  }
}

export function handleDayNotifications(dispatch, status) {
  if (status[dayActionMap.FETCH_DAYS].isError) {
    addNotification(dispatch, {
      message: "Fetching days failed.",
      dismissable: true,
      action: reloadAction
    });
  }

  if (status[dayActionMap.CREATE_DAY].isSuccess) {
    addNotification(dispatch, { message: "Day created." });
  }
  if (status[dayActionMap.CREATE_DAY].isError) {
    addNotification(dispatch, {
      message: "Day creation failed."
    });
  }

  if (status[dayActionMap.UPDATE_DAY].isSuccess) {
    addNotification(dispatch, { message: "Day updated." });
  }
  if (status[dayActionMap.UPDATE_DAY].isError) {
    addNotification(dispatch, { message: "Day update failed." });
  }

  if (status[dayActionMap.DELETE_DAY].isSuccess) {
    addNotification(dispatch, { message: "Day deleted." });
  }
  if (status[dayActionMap.DELETE_DAY].isError) {
    addNotification(dispatch, {
      message: "Day deletion failed."
    });
  }
}

export function handleTaskNotifications(dispatch, status) {
  if (status[taskActionMap.FETCH_TASKS].isError) {
    addNotification(dispatch, {
      message: "Fetching tasks failed.",
      dismissable: true,
      action: reloadAction
    });
  }
}
