import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Icon, Button, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import WeekDoctrine from "../Week/WeekDoctrine";

BookDetail.propTypes = {
  bookId: PropTypes.string.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.object)
};

function BookDetail({ bookId, weeks }) {
  // Grab unique doctrines
  const doctrines = weeks.reduce((unique, w) => {
    if (unique.indexOf(w.doctrine_name) === -1) {
      unique.push(w.doctrine_name);
    }
    return unique;
  }, []);

  // Create WeekDoctrines for each doctrine
  const weekDoctrines = doctrines.reduce((groups, d) => {
    const weeksInDoctrine = weeks.filter(w => w.doctrine_name === d);
    groups.push(<WeekDoctrine key={d} doctrine={d} weeks={weeksInDoctrine} />);
    return groups;
  }, []);

  return weeks.length === 0 ? (
    <Segment placeholder>
      <Header icon>
        <Icon name="calendar alternate outline" />
        No weeks are listed for this book.
      </Header>
      <Button
        as={Link}
        to={{ pathname: "/week/add", state: { bookId } }}
        primary
      >
        Add Week
      </Button>
    </Segment>
  ) : (
    <Segment as="section" raised>
      <Label as="h2" attached="top">
        Weeks
      </Label>
      {weekDoctrines}
    </Segment>
  );
}

export default BookDetail;
