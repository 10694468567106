import React from "react";
import ReactDOM from "react-dom";

import "semantic-ui-less/semantic.less";

import App from "./components/App";
import AppProviders from "./contexts";

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
);
