import React from "react";
import { useNotificationState } from "../../contexts/notification-context";
import Notification from "./Notification";

import "./Notification.css";

function NotificationContainer() {
  const { notifications } = useNotificationState();

  const notificationMsgs = notifications.map(n => {
    return <Notification key={n.id} notification={n} />;
  });

  return (
    notifications.length > 0 && (
      <div className="notification__container">{notificationMsgs}</div>
    )
  );
}

export default NotificationContainer;
