import http from "../utils/http";

function fetchDays() {
  return http
    .get("/day")
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function createDay(payload) {
  return http
    .post("/day", payload)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function updateDay(dayId, payload) {
  const config = {
    params: { day_id: dayId }
  };
  return http
    .patch("/day", payload, config)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function deleteDay(dayId) {
  const config = {
    params: { day_id: dayId }
  };
  return http
    .delete("/day", config)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

export { fetchDays, createDay, updateDay, deleteDay };
