import React, { useState, useEffect } from "react";
import SplashScreen from "../components/SplashScreen";
import * as authClient from "../clients/login-client";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth, setAuth] = useState({});

  // Runs once on component mount
  useEffect(() => {
    authClient.checkAuth().then(auth => {
      setAuth(auth);
      setIsCheckingAuth(false);
    });
  }, []);

  useEffect(() => {
    const isAuthenticated = auth ? (auth.idToken ? true : false) : false;
    setIsAuthenticated(isAuthenticated);
  }, [auth]);

  if (isCheckingAuth) {
    return <SplashScreen status="Checking authentication..." />;
  }

  const login = form =>
    authClient
      .login(form)
      .then(auth => {
        setAuth(auth);
      })
      .catch(err => {
        throw err;
      });

  const logout = () => {
    authClient.logout();
    setAuth({});
  };

  return (
    <AuthContext.Provider
      value={{ auth, isAuthenticated, login, logout }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
