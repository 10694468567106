import React from "react";
import PropTypes from "prop-types";
import { Item, Button, Icon, Label } from "semantic-ui-react";
import { Link, useRouteMatch } from "react-router-dom";
import ConfirmationButton from "../ConfirmationButton";
import {
  useDayState,
  useDayDispatch,
  actionMap,
  deleteDay
} from "../../contexts/day-context";
import stripTags from "striptags";

DayItem.propTypes = {
  day: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    devotion: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    reference: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

function DayItem({ day }) {
  const { topic, devotion, reference, number, _id } = day;
  const { url } = useRouteMatch();

  const { status } = useDayState();
  const dispatch = useDayDispatch();

  const deleteButton = (
    <Button animated="vertical">
      <Button.Content hidden>Delete</Button.Content>
      <Button.Content visible>
        <Icon name="trash" />
      </Button.Content>
    </Button>
  );

  return (
    <Item>
      <Item.Content>
        <Item.Header>{topic}</Item.Header>
        <Item.Meta>
          <Label>
            <Icon name="calendar" /> Day {number}
          </Label>
        </Item.Meta>
        <Item.Description>{stripTags(devotion)}</Item.Description>
        <Item.Extra>
          <Icon name="bookmark" />
          {reference}
        </Item.Extra>
        <Item.Extra>
          <Button compact primary as={Link} to={`${url}/day/${_id}`}>
            View
          </Button>
          <Button.Group compact floated="right">
            <Button as={Link} to={`${url}/day/${_id}/edit`} animated="vertical">
              <Button.Content hidden>Edit</Button.Content>
              <Button.Content visible>
                <Icon name="edit" />
              </Button.Content>
            </Button>
            <ConfirmationButton
              trigger={deleteButton}
              handleAction={() => {
                deleteDay(dispatch, _id);
              }}
              loading={status[actionMap.DELETE_DAY].isPending}
            />
          </Button.Group>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
}

export default DayItem;
