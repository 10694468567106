import React from "react";
import LoginPage from "../../pages/login";
import Layout from "../Layout";

function UnauthenticatedApp() {
  return (
    <Layout>
      <LoginPage />
    </Layout>
  );
}

export default UnauthenticatedApp;
