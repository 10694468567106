import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Logo } from "../assets/logos/tt-logo.svg";
import { Loader, Dimmer } from "semantic-ui-react";

SplashScreen.propTypes = {
  status: PropTypes.string
};

function SplashScreen({ status }) {
  return (
    <Dimmer active inverted page>
      <Logo style={{ width: "200px", position: "relative", bottom: "80%" }} />
      <Loader indeterminate size="large" content={status} />
    </Dimmer>
  );
}

export default SplashScreen;
