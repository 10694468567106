import React from "react";
import BookDetail from "../../components/Book/BookDetail";
import Page from "../../components/Page";
import { getBookTitle } from "../../utils/helpers";
import { useParams, useRouteMatch, Link, Redirect } from "react-router-dom";
import { convertYearToColor } from "../../utils/helpers";
import { Button, Dropdown } from "semantic-ui-react";
import ConfirmationButton from "../../components/ConfirmationButton";
import {
  useBookState,
  useBookDispatch,
  deleteBook,
  actionMap
} from "../../contexts/book-context";
import { useWeekState } from "../../contexts/week-context";

function BookDetailPage() {
  const { bookId } = useParams();
  const { map: bookMap, status } = useBookState();
  const dispatch = useBookDispatch();

  const { map: weekMap } = useWeekState();

  const book = bookMap[bookId];

  const year = book ? book.year : "";
  const color = convertYearToColor(year);

  const { url } = useRouteMatch();

  if (!book) {
    return <Redirect to="/book" />;
  }

  const actions = (
    <Button.Group color="blue">
      <Button as={Link} to={{ pathname: "/week/add", state: { bookId } }}>
        Add Week
      </Button>
      <Dropdown button className="button icon">
        <Dropdown.Menu>
          <Dropdown.Item
            text="Edit Book"
            as={Link}
            to={`${url}/edit`}
            icon="edit"
          />
          <ConfirmationButton
            trigger={<Dropdown.Item text="Delete Book" icon="trash" />}
            handleAction={() => {
              deleteBook(dispatch, bookId);
            }}
            triggerAction="hover"
            loading={status[actionMap.DELETE_BOOK].isPending}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  );

  const bookTitle = getBookTitle(book);
  const weeks = Object.values(weekMap).filter(w => w.book_id === bookId);

  const crumbs = [
    {
      name: "Book",
      path: "/book"
    }
  ];

  return (
    <Page
      title={bookTitle}
      color={color}
      icon="book"
      actions={actions}
      crumbs={crumbs}
    >
      <BookDetail bookId={bookId} weeks={weeks} />
    </Page>
  );
}

export default BookDetailPage;
