import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Container, Header, Grid, Icon, Segment } from "semantic-ui-react";
import NavBreadcrumbs from "./NavBreadcrumbs";

Page.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  containerWidth: PropTypes.string,
  icon: PropTypes.string,
  actions: PropTypes.element,
  color: PropTypes.string,
  crumbs: PropTypes.arrayOf(PropTypes.object)
};

function Page({
  title,
  containerWidth,
  icon,
  actions,
  color,
  subtitle,
  crumbs,
  children
}) {
  const containerWidthProp = containerWidth ? { [containerWidth]: true } : {};

  useEffect(() => {
    document.title = `${title} - Truth Trackers Admin`;
  }, [title]);

  return (
    <Container as="article" {...containerWidthProp}>
      {crumbs && (
        <Segment as="header" basic>
          <NavBreadcrumbs crumbs={crumbs} />
        </Segment>
      )}

      <Grid columns={2} as="header">
        <Grid.Column>
          <Header color={color ? color : "blue"} as="h1">
            {icon && <Icon name={icon} />}
            <Header.Content>
              {title}
              {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="right">{actions}</Grid.Column>
      </Grid>
      {children}
    </Container>
  );
}

export default Page;
