import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";
import { Form, Message, Header, Segment } from "semantic-ui-react";
import { useForm } from "../../hooks/form-hook";
import { required } from "../../utils/form-validators";
import { useWeekState, actionMap } from "../../contexts/week-context";

WeekForm.propTypes = {
  week: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    book_id: PropTypes.string.isRequired,
    doctrine_name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    verse: PropTypes.string.isRequired
  }),
  bookId: PropTypes.string,
  doctrineOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  bookOptions: PropTypes.arrayOf(PropTypes.object),
  weekAction: PropTypes.func.isRequired,
  type: PropTypes.string,
  text: PropTypes.string
};

function WeekForm({
  week,
  doctrineOptions: doctrineOptionsFromProps,
  bookOptions,
  bookId,
  weekAction,
  type = actionMap.CREATE_WEEK,
  text = "Add"
}) {
  const [doctrineOptions, setDoctrineOptions] = useState([
    ...doctrineOptionsFromProps
  ]);

  const fields = {
    book_id: week ? week.book_id : bookId ? bookId : bookOptions[0].value,
    doctrine_name: week ? week.doctrine_name : "",
    number: week ? week.number : 1,
    question: week ? week.question : "",
    reference: week ? week.reference : "",
    topic: week ? week.topic : "",
    verse: week ? week.verse : ""
  };

  const validators = {
    book_id: [required],
    doctrine_name: [required],
    number: [required],
    question: [required],
    reference: [required],
    topic: [required],
    verse: [required]
  };

  const submitCallback = () => {
    weekAction({ ...values, number: parseInt(values.number, 10) });
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    formError,
    setFormError,
    isFormInvalid
  } = useForm(fields, submitCallback, validators);

  function handleAddition(e, { value }) {
    setDoctrineOptions([...doctrineOptions, { text: value, value }]);
  }

  const { status, error } = useWeekState();

  useEffect(() => {
    if (error) {
      setFormError({
        hasError: true,
        title: `${text} Failed`,
        message: error.message
      });
    }
  }, [setFormError, error, text]);

  return status[type].isSuccess ? (
    <Redirect to={`/book/${values.book_id}`} />
  ) : (
    <Segment raised>
      <Form
        onSubmit={handleSubmit}
        loading={status[type].isPending}
        error={formError.hasError}
        noValidate
      >
        <Message error header={formError.title} content={formError.message} />
        <Header dividing size="small" content="Meta" color="orange" />
        <Form.Select
          required
          fluid
          options={bookOptions}
          id="bookId"
          name="book_id"
          label="Book"
          error={
            errors.book_id.touched && errors.book_id.message
              ? errors.book_id.message
              : false
          }
          value={values.book_id}
          onChange={handleChange}
        />
        <Form.Group widths="equal">
          <Form.Select
            allowAdditions
            required
            id="doctrine"
            name="doctrine_name"
            label="Doctrine"
            search
            error={
              errors.doctrine_name.touched && errors.doctrine_name.message
                ? errors.doctrine_name.message
                : false
            }
            options={doctrineOptions}
            value={values.doctrine_name}
            onAddItem={handleAddition}
            onChange={handleChange}
          />
          <Form.Input
            width={4}
            required
            id="number"
            name="number"
            label="Week Number"
            type="number"
            min="1"
            error={
              errors.number.touched && errors.number.message
                ? errors.number.message
                : false
            }
            value={values.number}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>

        <Header dividing size="small" content="Week Info" color="orange" />
        <Form.Input
          required
          id="topic"
          name="topic"
          label="Topic"
          error={
            errors.topic.touched && errors.topic.message
              ? errors.topic.message
              : false
          }
          value={values.topic}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Form.Input
          required
          id="question"
          name="question"
          label="Question"
          error={
            errors.question.touched && errors.question.message
              ? errors.question.message
              : false
          }
          value={values.question}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Form.TextArea
          required
          id="verse"
          name="verse"
          label="Verse"
          error={
            errors.verse.touched && errors.verse.message
              ? errors.verse.message
              : false
          }
          value={values.verse}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Form.Input
          required
          id="reference"
          name="reference"
          label="Reference"
          error={
            errors.reference.touched && errors.reference.message
              ? errors.reference.message
              : false
          }
          value={values.reference}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Form.Button primary disabled={isFormInvalid} content={text} />
      </Form>
    </Segment>
  );
}

export default WeekForm;
