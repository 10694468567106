import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Icon,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { useAuth } from "../../contexts/authentication-context";
import { useForm } from "../../hooks/form-hook";
import { required } from "../../utils/form-validators";

LoginForm.propTypes = {
  captureUsername: PropTypes.func.isRequired
};

function LoginForm({ captureUsername }) {
  const { login } = useAuth();

  const validators = {
    username: [required],
    password: [required]
  };

  const submitCallback = () => {
    captureUsername(values.username);
    login(values).catch(err => {
      setFormError({
        hasError: true,
        title: "Login Failed",
        message: err.message
      });
      setIsSubmitting(false);
    });
  };

  const fields = {
    username: "",
    password: ""
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    formError,
    setFormError,
    isFormInvalid
  } = useForm(fields, submitCallback, validators);

  return (
    <Segment raised>
      <Header as="h2">Welcome Back</Header>
      <Form
        onSubmit={handleSubmit}
        loading={isSubmitting}
        error={formError.hasError}
        noValidate
      >
        <Message error header={formError.title} content={formError.message} />

        <Form.Input
          type="email"
          label="Username"
          id="username"
          name="username"
          required
          error={
            errors.username.touched && errors.username.message
              ? errors.username.message
              : false
          }
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Form.Input
          type="password"
          label="Password"
          id="password"
          name="password"
          required
          error={
            errors.password.touched && errors.password.message
              ? errors.password.message
              : false
          }
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Form.Button primary animated disabled={isFormInvalid}>
          <Button.Content visible>Login</Button.Content>
          <Button.Content hidden>
            <Icon name="sign-in" />
          </Button.Content>
        </Form.Button>
      </Form>
    </Segment>
  );
}

export default LoginForm;
