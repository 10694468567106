import React from "react";
import { AuthProvider } from "./authentication-context";
import { NotificationProvider } from "./notification-context";

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <NotificationProvider>{children}</NotificationProvider>
    </AuthProvider>
  );
}

export default AppProviders;
