import http from "../utils/http";

function fetchWeeks() {
  return http
    .get("/week")
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function createWeek(payload) {
  return http
    .post("/week", payload)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function updateWeek(weekId, payload) {
  const config = {
    params: { week_id: weekId }
  };
  return http
    .patch("/week", payload, config)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

function deleteWeek(weekId) {
  const config = {
    params: { week_id: weekId }
  };
  return http
    .delete("/week", config)
    .then(({ data }) => data)
    .catch(err => {
      throw new Error(err);
    });
}

export { fetchWeeks, createWeek, updateWeek, deleteWeek };
